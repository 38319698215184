import { useEffect, useState, Fragment } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';
import privacy from './modules/views/privacy.md';

function Privacy() {
  const [postMarkdown, setPostMarkdown] = useState('Loading');

  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    fetch(privacy)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdown(text);
      });
  }, []);
  return (
    <Fragment>
      <AppAppBar />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Privacy
          </Typography>
          <Markdown>{postMarkdown}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </Fragment>
  );
}

export default withRoot(Privacy);
