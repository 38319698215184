import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import { email, required } from './modules/form/validation';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';
import withRoot from './modules/withRoot';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID
const TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID
const USER_ID = process.env.REACT_APP_EMAIL_USER_ID

function SignIn() {
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email', 'name', 'message'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    console.log('event:', event)
    emailjs.init(USER_ID)
    emailjs.send(SERVICE_ID, TEMPLATE_ID, {
      from_name: event.name,
      to_name: "KhotSanook",
      message: event.message,
      from_email: event.email
    }).then((result) => {
      console.log(result.text);
      Swal.fire({
        icon: 'success',
        title: 'Message Sent Successfully'
      })
    }).catch((error) => {
      console.log(error.text);
      Swal.fire({
        icon: 'error',
        title: 'Ooops, something went wrong',
        text: error.text,
      })
    }).finally(() => {
      setSent(true);
    }, USER_ID)
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Contact Us
          </Typography>
          <Typography variant="body2" align="center">
            {'We are desperately waiting for your feedback. WooHoo!!! '}
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
              <Field
                fullWidth
                // size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="name"
                autoComplete="name"
                label="Name"
                type="text"
                margin="normal"
              />
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                // size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="message"
                autoComplete="message"
                label="Message"
                type="textarea"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {sent ? 'Sent successfully' : (submitting? 'Sending…' : 'Submit')}
              </FormButton>
            </Box>
          )}
        </Form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(SignIn);
