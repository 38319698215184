import './App.css';
import Home from './onepirate/Home';
import Privacy from './onepirate/Privacy';
import ContactUs from './onepirate/ContactUs';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default App;
